<template lang="pug">
  v-card.user-settings
    v-scrollable(:bottom-offset="20")
      v-card-content
        .wrapper
          h2.title {{ 'pages.settings.user' | translate }}
          p.subtitle {{ 'infotext.this_page_for_default_settings' | translate }}
          .row
            .col-md-6
              .form-group
                .col-wrapper
                  .col-item
                    label.subtitle {{ 'infotext.myskilltree_default_view' | translate }}
                    v-info-icon(:text="'infotext.the_default_view_for_all_users' | translate")
                  .col-item
                    v-select(
                      single
                      hideBottomMargin
                      :skeleton="!loaded"
                      :label="'ui.labels.my_skill_profile' | translate"
                      :options="getListViews"
                      :value="getDefaultTreeView"
                      @input="setDefaultTreeType")
              .form-group
                .col-wrapper
                  .col-item
                    label.subtitle {{ 'ui.labels.default_language' | translate }}
                    v-info-icon(:text="'infotext.default_language_description' | translate")
                  .col-item
                    v-select(
                      single
                      hideBottomMargin
                      track-by="title"
                      :skeleton="!loaded"
                      :label="'ui.labels.default_language' | translate"
                      :options="languages"
                      v-model="lang")
            .col-md-6
              .form-group
                .col-wrapper
                  .col-item
                    label.subtitle {{ 'infotext.myskilltree_default_look' | translate }}
                    v-info-icon(:text="'infotext.select_the_default_visualization' | translate")
                  .col-item
                    v-select(
                      single
                      hideBottomMargin
                      :skeleton="!loaded"
                      track-by="title"
                      :options="getListDesigns"
                      :value="isSkilltreeViewDefault"
                      @input="setSkillTreeDefaultLook")
              .form-group
                .col-wrapper
                  .col-item
                    label.subtitle {{ 'infotext.skilltree_builder_default_look' | translate }}
                    v-info-icon(:text="'infotext.select_the_default_visualization_builder' | translate")
                  .col-item
                    v-select(
                      single
                      hideBottomMargin
                      :skeleton="!loaded"
                      track-by="title"
                      :options="getListBuilders"
                      :value="isBuilderViewDefault"
                      @input="setBuilderDefaultLook")
            .col-md-12
              .form-group
                .col-wrapper
                  .col-item
                    label.subtitle  {{ 'infotext.prevent_employees_from_overwriting_default_look' | translate }}
                    v-info-icon(:text="'infotext.prevent_employees_from_changing' | translate")
                    v-switch(
                      icon
                      noMargin
                      outline
                      label=""
                      label-position="left"
                      :value="isDesignDisableLookSelection"
                      @input="setDisableLookSelection")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TREE_OPTIONS, BUILDER_OPTIONS, TREE_VIEWS_LIST } from '@/util/constants.js'

export default {
  name: 'UserSettings',

  mounted () {
    Promise.all([
      this.loadLanguages(),
      this.getSkilltreeDefaultLook(),
      this.getBuilderDefaultLook(),
      this.loadDefaultTreeType(),
      this.getCompanyInfo()
    ]).then(() => {
      this.loaded = true
      this.lang = this.isCompanyInfo ? this.isCompanyInfo.defaultLanguage : this.languages[0]
    })
  },

  data: (vm) => ({
    loaded: false,
    lang: null
  }),

  computed: {
    ...mapGetters('app', [
      'languages',
      'isDesignDisableLookSelection',
      'isSkilltreeViewDefault',
      'isBuilderViewDefault',
      'isCompanyInfo'
    ]),

    ...mapGetters('trees', [
      'getDefaultTreeView'
    ]),

    getListDesigns () {
      return TREE_OPTIONS
    },

    getListBuilders () {
      return BUILDER_OPTIONS
    },

    getListViews () {
      return TREE_VIEWS_LIST
    }
  },

  methods: {
    ...mapActions('app', [
      'loadLanguages',
      'setDisableLookSelection',
      'setSkillTreeDefaultLook',
      'setBuilderDefaultLook',
      'getSkilltreeDefaultLook',
      'getBuilderDefaultLook',
      'getCompanyInfo',
      'setCompanyInfo'
    ]),

    ...mapActions('trees', [
      'loadDefaultTreeType',
      'setDefaultTreeType'
    ])
  },
  watch: {
    lang (value, valueOld) {
      if (value && valueOld !== undefined && valueOld !== null) {
        const payload = { ...this.isCompanyInfo, defaultLanguageId: this.lang.id }
        this.setCompanyInfo(payload)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-settings {

  .v-card__content {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .title {
    margin-bottom: 16px;
  }
}

.wrapper {
  min-height: 340px;
}

.cols {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  .col-wrapper {
    display: flex;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }

  .col-item {
    display: flex;
    align-items: center;
    flex: 1 1 48%;

    ::v-deep .v-info-icon {
      color: $body-text-2;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}
</style>
